define("discourse/plugins/swapd-user-feedback/discourse/components/feedback-stream", ["exports", "discourse/components/user-stream", "discourse/lib/ajax", "@ember/helper", "@ember/modifier", "discourse/lib/ajax-error", "@ember/service", "@ember/application", "discourse/plugins/swapd-user-feedback/discourse/components/modal/dispute-feedback", "@ember/object", "@glimmer/tracking", "discourse/plugins/swapd-user-feedback/discourse/components/feedback-stream-item", "discourse/components/post-list", "discourse/components/plugin-outlet", "discourse/components/post-action-description", "discourse/helpers/concat-class", "discourse/helpers/d-icon", "discourse/helpers/avatar", "@ember/component", "@ember/template-factory"], function (_exports, _userStream, _ajax, _helper, _modifier, _ajaxError, _service, _application, _disputeFeedback2, _object, _tracking, _feedbackStreamItem, _postList, _pluginOutlet, _postActionDescription, _concatClass, _dIcon, _avatar, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class FeedbackStream extends _userStream.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.inject]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "isLoading", [_tracking.tracked], function () {
      return false;
    }))();
    #isLoading = (() => (dt7948.i(this, "isLoading"), void 0))();
    deleteFeedback(id) {
      this.dialog.confirm({
        message: I18n.t("user_feedback.confirm_delete"),
        confirmButtonClass: 'btn-danger',
        didConfirm: () => {
          this.performDeleteFeedback(id);
        },
        didCancel: () => {
          return;
        }
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "deleteFeedback", [_object.action]))();
    disputeFeedback(feedback) {
      let modal = (0, _application.getOwner)(this).lookup("service:modal");
      const result = modal.show(_disputeFeedback2.default, {
        model: {
          user: this.args.stream.user,
          raw: "",
          feedback: feedback
        }
      });
      modal.setProperties({
        loading: false,
        feedback,
        user: this.args.stream.user,
        raw: ""
      });
    }
    static #_4 = (() => dt7948.n(this.prototype, "disputeFeedback", [_object.action]))();
    clearDispute(feedback) {
      this.dialog.confirm({
        message: I18n.t("user_feedback.confirm_clear_dispute"),
        confirmButtonClass: 'btn-danger',
        didConfirm: () => {
          this.performClearDispute(feedback);
        },
        didCancel: () => {
          return;
        }
      });
    }
    static #_5 = (() => dt7948.n(this.prototype, "clearDispute", [_object.action]))();
    performDeleteFeedback(id) {
      this.isLoading = true;
      var stream = this.args.stream;
      (0, _ajax.ajax)(`/user-feedback/u/${stream.user.id}/${id}.json`, {
        type: "DELETE"
      }).then(data => {
        const action = stream.content.findBy("id", id);
        if (action) {
          stream.content.removeObject(action);
          stream.set("itemsLoaded", stream.itemsLoaded - 1);
        }
        stream.user.average_rating = data.average_rating;
        stream.user.ratings_count = data.ratings_count;
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        this.isLoading = false;
      });
    }
    performClearDispute(feedback) {
      this.isLoading = true;
      var stream = this.args.stream;
      (0, _ajax.ajax)(`/user-feedback/u/${stream.user.id}/${feedback.id}/clear`, {
        type: "POST"
      }).then(data => {
        feedback.set("disputed", false);
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        this.isLoading = false;
      });
    }
    static #_6 = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <ul class="user-stream">
          {{#each this.args.stream.content as |item|}}
            <FeedbackStreamItem
              @item={{item}}
              @user={{this.args.stream.user}}
              @isLoading={{this.isLoading}}
              @deleteFeedback={{action "deleteFeedback" item.id}}
              @disputeFeedback={{action "disputeFeedback" item}}
              @clearDispute={{action "clearDispute" item}}
            />
          {{/each}}
        </ul>
      
    */
    {
      "id": "mlB+Qp2A",
      "block": "[[[1,\"\\n    \"],[10,\"ul\"],[14,0,\"user-stream\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"args\",\"stream\",\"content\"]]],null]],null],null,[[[1,\"        \"],[8,[32,0],null,[[\"@item\",\"@user\",\"@isLoading\",\"@deleteFeedback\",\"@disputeFeedback\",\"@clearDispute\"],[[30,1],[30,0,[\"args\",\"stream\",\"user\"]],[30,0,[\"isLoading\"]],[28,[31,2],[[30,0],\"deleteFeedback\",[30,1,[\"id\"]]],null],[28,[31,2],[[30,0],\"disputeFeedback\",[30,1]],null],[28,[31,2],[[30,0],\"clearDispute\",[30,1]],null]]],null],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"item\"],false,[\"each\",\"-track-array\",\"action\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/swapd-user-feedback/discourse/components/feedback-stream.js",
      "scope": () => [_feedbackStreamItem.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = FeedbackStream;
});
define("discourse/plugins/swapd-user-feedback/discourse/templates/connectors/user-profile-primary/rating-average", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.siteSettings.user_feedback_enabled}}
    <RatingStars
      @rating={{this.args.model.average_rating}}
      @ratingsCount={{this.args.model.ratings_count}}
      @readOnly={{true}}
      @showLink={{false}}
      @user={{this.args.model}}
    />
  {{/if}}
  
  */
  {
    "id": "wxOQefXn",
    "block": "[[[41,[30,0,[\"siteSettings\",\"user_feedback_enabled\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@rating\",\"@ratingsCount\",\"@readOnly\",\"@showLink\",\"@user\"],[[30,0,[\"args\",\"model\",\"average_rating\"]],[30,0,[\"args\",\"model\",\"ratings_count\"]],true,false,[30,0,[\"args\",\"model\"]]]],null],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"rating-stars\"]]",
    "moduleName": "discourse/plugins/swapd-user-feedback/discourse/templates/connectors/user-profile-primary/rating-average.hbs",
    "isStrictMode": false
  });
});
define("discourse/plugins/swapd-user-feedback/discourse/components/rating-stars", ["exports", "@glimmer/component", "@ember/object", "@glimmer/tracking", "@ember/modifier", "@ember/routing", "discourse-common/helpers/i18n", "@ember/render-modifiers/modifiers/did-insert", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _tracking, _modifier, _routing, _i18n, _didInsert, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RatingStars extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "rating", [_tracking.tracked], function () {
      return this.args.rating || 0;
    }))();
    #rating = (() => (dt7948.i(this, "rating"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "stars", [_tracking.tracked], function () {
      return [];
    }))();
    #stars = (() => (dt7948.i(this, "stars"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "ratingContainer", [_tracking.tracked], function () {
      return null;
    }))();
    #ratingContainer = (() => (dt7948.i(this, "ratingContainer"), void 0))();
    setRatingContainer(element) {
      this.ratingContainer = element;
    }
    static #_4 = (() => dt7948.n(this.prototype, "setRatingContainer", [_object.action]))();
    get starsNumber() {
      return this.args.starsNumber || 5;
    }
    get showLink() {
      return this.args.showLink ?? false;
    }
    get showHelpText() {
      return this.args.showHelpText ?? false;
    }
    get readOnly() {
      return this.args.readOnly ?? true;
    }
    get ratingsCount() {
      return this.args.ratingsCount || false;
    }
    constructor() {
      super(...arguments);
      this.updateStars();
    }
    updateStars() {
      this.stars = Array.from({
        length: this.starsNumber
      }, (_, i) => ({
        full: i < this.rating
      }));
    }
    updateRating(newRating) {
      if (this.readOnly) return;
      this.rating = newRating;
      this.updateStars();
      if (typeof this.args.onRate === "function") {
        this.args.onRate(newRating);
      }
    }
    static #_5 = (() => dt7948.n(this.prototype, "updateRating", [_object.action]))();
    handleClick(event) {
      if (this.readOnly) return;
      let rating = this.getTarget(event.pageX);
      if (rating <= this.starsNumber) {
        this.updateRating(rating);
      }
    }
    static #_6 = (() => dt7948.n(this.prototype, "handleClick", [_object.action]))();
    getTarget(x) {
      if (!this.ratingContainer) return this.rating;
      const leftOffset = this.ratingContainer.getBoundingClientRect().left;
      const width = this.ratingContainer.clientWidth;
      return Math.ceil(this.starsNumber * (x - leftOffset) / width);
    }
    static #_7 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="rating-stars-container">
          {{#if this.showHelpText}}
            <strong>{{i18n "user_feedback.add_your_rating"}}</strong>
          {{/if}}
          <div class="rating-stars" {{didInsert this.setRatingContainer}}
            {{on "click" this.handleClick}}
            style={{if this.readOnly "" "cursor:pointer"}}>
            {{#each this.stars as |star index|}}
              <span class="star-icon {{if star.full "full"}}" data-index={{index}}>☆</span>
            {{/each}}
          </div>
          {{#if this.ratingsCount}}
            <div class="ratings-count">
              {{i18n "user_feedback.ratings_count" ratingsCount=this.ratingsCount}}
            </div>
          {{/if}}
          {{#if this.showLink}}
            <LinkTo @route="user.feedback" @model={{this.args.user.username}} class="btn btn-primary btn-small">{{i18n "user_feedback.feedback_button"}}</LinkTo>
          {{/if}}
        </div>
      
    */
    {
      "id": "kDj6+zkq",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"rating-stars-container\"],[12],[1,\"\\n\"],[41,[30,0,[\"showHelpText\"]],[[[1,\"        \"],[10,\"strong\"],[12],[1,[28,[32,0],[\"user_feedback.add_your_rating\"],null]],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[11,0],[24,0,\"rating-stars\"],[16,5,[52,[30,0,[\"readOnly\"]],\"\",\"cursor:pointer\"]],[4,[32,1],[[30,0,[\"setRatingContainer\"]]],null],[4,[32,2],[\"click\",[30,0,[\"handleClick\"]]],null],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,0,[\"stars\"]]],null]],null],null,[[[1,\"          \"],[10,1],[15,0,[29,[\"star-icon \",[52,[30,1,[\"full\"]],\"full\"]]]],[15,\"data-index\",[30,2]],[12],[1,\"☆\"],[13],[1,\"\\n\"]],[1,2]],null],[1,\"      \"],[13],[1,\"\\n\"],[41,[30,0,[\"ratingsCount\"]],[[[1,\"        \"],[10,0],[14,0,\"ratings-count\"],[12],[1,\"\\n          \"],[1,[28,[32,0],[\"user_feedback.ratings_count\"],[[\"ratingsCount\"],[[30,0,[\"ratingsCount\"]]]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"showLink\"]],[[[1,\"        \"],[8,[32,3],[[24,0,\"btn btn-primary btn-small\"]],[[\"@route\",\"@model\"],[\"user.feedback\",[30,0,[\"args\",\"user\",\"username\"]]]],[[\"default\"],[[[[1,[28,[32,0],[\"user_feedback.feedback_button\"],null]]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"star\",\"index\"],false,[\"if\",\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/swapd-user-feedback/discourse/components/rating-stars.js",
      "scope": () => [_i18n.default, _didInsert.default, _modifier.on, _routing.LinkTo],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = RatingStars;
});
define("discourse/plugins/swapd-user-feedback/discourse/templates/user-feedback", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{body-class "user-feedback"}}
  
  <div class="user-feedback">
    <div class="user-feedback-left">
    </div>
  
    <div class="user-feedback-right">
      {{#if this.canCreateFeedback}}
          {{rating-composer stream=this.model}}
      {{/if}}
  
      <div class="user-feedback-filters">
        {{i18n "user_feedback.filter_posted_by"}}
  
        {{d-button
          label="user_feedback.seller"
          action=(action "toggleFilter" "posted_by" "seller")
          class=(f-filter-btn-class this.posted_by "seller")
        }}
  
        {{d-button
          label="user_feedback.buyer"
          action=(action "toggleFilter" "posted_by" "buyer")
          class=(f-filter-btn-class this.posted_by "buyer")
        }}
      </div>
  
      {{#if this.model.content}}
        {{feedback-stream stream=this.model}}
      {{else}}
        <div class="alert alert-info">
          {{i18n "user_feedback.no_feedback"}}
        </div>
      {{/if}}
    </div>
  </div>
  
  */
  {
    "id": "jzTGzLFN",
    "block": "[[[1,[28,[35,0],[\"user-feedback\"],null]],[1,\"\\n\\n\"],[10,0],[14,0,\"user-feedback\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"user-feedback-left\"],[12],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"user-feedback-right\"],[12],[1,\"\\n\"],[41,[30,0,[\"canCreateFeedback\"]],[[[1,\"        \"],[1,[28,[35,3],null,[[\"stream\"],[[30,0,[\"model\"]]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[10,0],[14,0,\"user-feedback-filters\"],[12],[1,\"\\n      \"],[1,[28,[35,4],[\"user_feedback.filter_posted_by\"],null]],[1,\"\\n\\n      \"],[1,[28,[35,5],null,[[\"label\",\"action\",\"class\"],[\"user_feedback.seller\",[28,[37,6],[[30,0],\"toggleFilter\",\"posted_by\",\"seller\"],null],[28,[37,7],[[30,0,[\"posted_by\"]],\"seller\"],null]]]]],[1,\"\\n\\n      \"],[1,[28,[35,5],null,[[\"label\",\"action\",\"class\"],[\"user_feedback.buyer\",[28,[37,6],[[30,0],\"toggleFilter\",\"posted_by\",\"buyer\"],null],[28,[37,7],[[30,0,[\"posted_by\"]],\"buyer\"],null]]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"model\",\"content\"]],[[[1,\"      \"],[1,[28,[35,8],null,[[\"stream\"],[[30,0,[\"model\"]]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[14,0,\"alert alert-info\"],[12],[1,\"\\n        \"],[1,[28,[35,4],[\"user_feedback.no_feedback\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"body-class\",\"div\",\"if\",\"rating-composer\",\"i18n\",\"d-button\",\"action\",\"f-filter-btn-class\",\"feedback-stream\"]]",
    "moduleName": "discourse/plugins/swapd-user-feedback/discourse/templates/user-feedback.hbs",
    "isStrictMode": false
  });
});
define("discourse/plugins/swapd-user-feedback/discourse/initializers/user-feedback", ["exports", "discourse-common/lib/icon-library", "discourse/lib/plugin-api", "discourse/lib/url"], function (_exports, _iconLibrary, _pluginApi, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initWithApi(api) {
    const siteSettings = api.container.lookup("site-settings:main");
    if (!siteSettings.user_feedback_enabled) return;
    const currentUser = api.getCurrentUser();
    if (currentUser) {
      api.addCommunitySectionLink({
        name: "user_feedback",
        href: `/u/${currentUser.username_lower}/feedback`,
        title: I18n.t("user_feedback.label"),
        text: I18n.t("user_feedback.label"),
        icon: "star"
      });
    }
  }
  var _default = _exports.default = {
    name: "user-feedback",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.7", initWithApi);
      (0, _iconLibrary.replaceIcon)("notification.user_feedback.notification", "star");
    }
  };
});
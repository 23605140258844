define("discourse/plugins/swapd-user-feedback/discourse/models/feedback", ["exports", "discourse/models/user-posts-stream", "discourse/lib/computed"], function (_exports, _userPostsStream, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _userPostsStream.default.extend({
    url: (0, _computed.url)("user.id", "itemsLoaded", "scoped", "postedBy", "/user-feedback/u/%@/feedback?offset=%@&scoped=%@&posted_by=%@")
  });
});
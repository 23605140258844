define("discourse/plugins/swapd-user-feedback/discourse/templates/connectors/user-card-metadata/rating-average", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.siteSettings.user_feedback_enabled}}
    <h3>
      <span class="desc">{{i18n "user_feedback.reputation"}}</span>
      <RatingStars
        @rating={{this.args.user.average_rating}}
        @ratingsCount={{this.args.user.ratings_count}}
        @readOnly={{true}}
        @showLink={{true}}
        @user={{this.args.user}}
      />
    </h3>
  {{/if}}
  
  */
  {
    "id": "dyf60hWk",
    "block": "[[[41,[30,0,[\"siteSettings\",\"user_feedback_enabled\"]],[[[1,\"  \"],[10,\"h3\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"desc\"],[12],[1,[28,[35,3],[\"user_feedback.reputation\"],null]],[13],[1,\"\\n    \"],[8,[39,4],null,[[\"@rating\",\"@ratingsCount\",\"@readOnly\",\"@showLink\",\"@user\"],[[30,0,[\"args\",\"user\",\"average_rating\"]],[30,0,[\"args\",\"user\",\"ratings_count\"]],true,true,[30,0,[\"args\",\"user\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"h3\",\"span\",\"i18n\",\"rating-stars\"]]",
    "moduleName": "discourse/plugins/swapd-user-feedback/discourse/templates/connectors/user-card-metadata/rating-average.hbs",
    "isStrictMode": false
  });
});
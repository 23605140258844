define("discourse/plugins/swapd-user-feedback/discourse/helpers/feedback-helpers", ["discourse-common/lib/helpers"], function (_helpers) {
  "use strict";

  (0, _helpers.registerHelper)("f-eq", params => {
    return params[0] == params[1];
  });
  (0, _helpers.registerHelper)("f-filter-btn-class", params => {
    const filter = params[0];
    const val = params[1];
    let klass = "btn-small ";
    klass += filter === val ? "btn-primary" : "btn-default";
    return klass;
  });
});